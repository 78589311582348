<template>
  <div class='plan'>
    <!-- 方案 -->
    <div><img src="@/assets/solution/20170327044723814.png" class="img" alt=""> </div>
    <div class="list">
      <div class="list_item">
        <a href="">金融行业</a>
      </div>
      <div class="list_item">
        <a href="">保险行业</a>
      </div>
      <div class="list_item">
        <a href="">医疗行业</a>
      </div>
      <div class="list_item">
        <a href="">政府单位</a>
      </div>
      <div class="list_item">
        <a href="">房地产行业</a>
      </div>
      <div class="list_item">
        <a href="">仓库物流</a>
      </div>
      <div class="list_item">
        <a href="">交通运输</a>
      </div>
      <div class="list_item">
        <a href="">教育传媒</a>
      </div>
      <div class="list_item">
        <a href="">微电商行业</a>
      </div>
      <div class="list_item">
        <a href="">移动信息化</a>
      </div>
    </div>

    <div class="text">
      <div class="title">金融行业的移动化趋势</div>
      <br>
      <br>
     <div class="box">
        <div class="content"> 从2007年以来，中国地产的投资模式、建筑与房地产行业发展走势及开发模式、经营模式显然已经发生了变化。房地产投资增速不断下降，房企资金状况仍然紧张，销售面积及销售额下降，房地产开发商不断向三四线城市进军和转移，表明房地产行业开始进入理性发展时期。 未来中国的房地产行业将告别暴利，告别野蛮生长。企业需要主动选择转型，需要更加注重打造符合市场的品牌。能否转型成功，取决于能力、眼光、创新和心态。找到适合自己的细分市场，提升企业品牌价值，方能赢得消费者的认可与信赖。 在这个关键时期，移动互联网的出现给房地产行业带来生机，也给房地产行业注入巨大的能量。移动互联网将彻底地改变地产交易模式、运营服务等领域，房地产互联网经济神话的延续将依赖于移动时代与房地产互联网的融合。</div>
        <img class="img" src="@/assets/solution/20170331102449217.png" alt="">
     </div>
      <div class="box1">
        <img class="img1" src="@/assets/solution/20170331104722230.png" alt="">
        <div class="right">
          <div class="title1">金融行业移动信息化解决方案</div>
          <div class="content1">
            <span>数字睿信凭借在移动信息化领域的技术优势和良好的客户口碑，已经帮助很多客户实现了上诉痛点需求， 构建了稳定、安全、高效、灵活的金融行业解决方案，依靠移 动平台和业务应用的完美配合，为金融行业安全护航。</span>
            <br>
            <br>
            <span>涉及功能模块：新闻资讯、市场活动、应用导向、消息推送、盈收结算、移动BI、知识库、客户管理、商机管理、产品发布等。</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang='less'>
    .plan{
      .img{
        width: 100%;
      }
      .list{
        display: flex;
        flex-wrap: wrap;
        padding: 0.1493rem 0;
        width: 100%;
        .list_item{
          width: 1.7164rem;
          margin-left: 0.2239rem;
          border: 0.0075rem solid #ccc;
          margin-bottom: 0.0746rem;
          line-height: 0.3209rem;
          text-align: center;
          a{
            font-size: 0.1343rem;
          }
        }
      }
    }

    .text{
      margin: 0.2239rem;
      .title{
        color: rgb(255, 0, 0);
        font-family: 微软雅黑;
        font-size: 0.1343rem;
      }
      .box{
        display: flex;
        .content{
          font-family: 微软雅黑;
          font-size: 0.1194rem;
          padding-right: 0.0746rem;
        }
        .img{
          width: 3.6567rem;
          height: 2.5373rem;
        }
      }
      .box1{
        // margin-top: -0.7463rem;
        display: flex;
        .img1{
          height: 2.9851rem;
          width: 2.9851rem;
        }
        .right{
          display: flex;
          flex-direction: column;
          margin-left: 0.0746rem;
          .title1{
            color: rgb(255, 0, 0);
            font-family: 微软雅黑;
            font-size: 0.1343rem;
            margin-top: 0.2985rem;
          }
          .content1{
            font-family: 微软雅黑;
            font-size: 0.1194rem;
            padding-top: 0.2985rem;
          }
        }
      }
    }
</style>
